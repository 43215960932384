import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ImageElement } from "../UiElement";
import { getSideBarMenu } from "../../utils/common.util";
import superadminRoutesMap from "../../routeControl/superadminRoutes";

function SuperAdminSidebar({ setActive, routeList }) {
  const location = useLocation();
  let menu = useMemo(() => {
    let data = getSideBarMenu(routeList);
    return data;
  }, [routeList]);

  const ToggleOverlay = () => {
    document.querySelector("body").classList.remove("openSidebar");
    setActive(false);
    document
      .querySelector(".adminHeader_click em")
      .classList.remove("tripon-close");
    document
      .querySelector(".adminHeader_click em")
      .classList.add("tripon-menu");
  };

  const getActiveMenu = (pathname) => {
    let path = location?.pathname;
    path = location?.pathname.includes("user-details")
      ? superadminRoutesMap.USERLIST.path
      : path;
    path = location?.pathname.includes("vendor-details")
      ? superadminRoutesMap.VENDORLIST.path
      : path;
    path = location?.pathname.includes("trip-details")
      ? superadminRoutesMap.TRIPSLIST.path
      : path;
    path = location?.pathname.includes("booking-details")
      ? superadminRoutesMap.BOOKLIST.path
      : path;
    return path === pathname;
  };
  return (
    <>
      <aside className="sideBar">
        <div className="sideBar_logo">
          <Link to="/super-admin/dashboard" className="d-block text-center">
            <ImageElement source="logo-sidebar.svg" className="img-fluid" />
          </Link>
        </div>
        <div className="sideBar_list">
          {menu?.map((item, key) => {
            return (
              <div
                className={`sideBar_list_items ${
                  getActiveMenu(item?.path) ? "active" : ""
                }`}
                key={key}
              >
                <Link to={item?.path} className="sideBar_list_menu">
                  <em className={item?.icon} /> {item?.label}
                </Link>
              </div>
            );
          })}
          {/* <div className="sideBar_list_items active">
            <Link to="/super-admin/dashboard" className="sideBar_list_menu"><em className="tripon-dashboard" /> Dashboard</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/offer-banner" className="sideBar_list_menu"><em className="tripon-percent" /> Banner</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/user-list" className="sideBar_list_menu"><em className="tripon-user" />User List </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/vendor-list" className="sideBar_list_menu"><em className="tripon-vendor" />Vendor List </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/trip-list" className="sideBar_list_menu"><em className="tripon-trips" />Trip List </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/booking-list" className="sideBar_list_menu"><em className="tripon-booking" />Booking List</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/payment-list" className="sideBar_list_menu"><em className="tripon-payment" />Payment </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/report-list" className="sideBar_list_menu"><em className="tripon-analytics" />Reports </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/review-list" className="sideBar_list_menu"><em className="tripon-star" />Review List</Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/support-list" className="sideBar_list_menu"><em className="tripon-support" />Support </Link>
          </div>
          <div className="sideBar_list_items">
            <Link to="/super-admin/cms-list" className="sideBar_list_menu"><em className="tripon-file" />CMS </Link>
          </div> */}
        </div>
      </aside>
      <div className="sideOverlay" onClick={() => ToggleOverlay()} />
    </>
  );
}

export default SuperAdminSidebar;
