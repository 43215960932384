import { baseRoutes } from "../../../helpers";

const accessRoute = {
  HOME: {
    path: `${baseRoutes.userBaseRoutes}`,
  },
  UPTRIP: {
    path: `${baseRoutes.userBaseRoutes}trips`,
  },
  SEARCH: {
    path: `${baseRoutes.userBaseRoutes}search`,
  },
  FILTER: {
    path: `${baseRoutes.userBaseRoutes}filter`,
  },
  DETAILS: {
    path: `${baseRoutes.userBaseRoutes}trip-details`,
  },
  GUIDE: {
    path: `${baseRoutes.userBaseRoutes}tour-guide`,
  },
  REVIEW: {
    path: `${baseRoutes.userBaseRoutes}review`,
  },
  DAYS: {
    path: `${baseRoutes.userBaseRoutes}days-plan`,
  },
  POLICIES: {
    path: `${baseRoutes.userBaseRoutes}privacy`,
  },
  TERMS: {
    path: `${baseRoutes.userBaseRoutes}terms`,
  },
  NOTIFICATION: {
    path: `${baseRoutes.userBaseRoutes}notification`,
  },
  CHECKOUT: {
    path: `${baseRoutes.userBaseRoutes}checkout`,
  },
  PROFILES: {
    path: `${baseRoutes.userBaseRoutes}my-profile`,
  },
  EDITPROFILES: {
    path: `${baseRoutes.userBaseRoutes}edit-profile`,
  },
  CHNAGEPWD: {
    path: `${baseRoutes.userBaseRoutes}change-password`,
  },
  BOOKING: {
    path: `${baseRoutes.userBaseRoutes}booking-history`,
  },
  BOOKINGHISTORY: {
    path: `${baseRoutes.userBaseRoutes}booking-history-details`,
  },
  BOOKINGPAST: {
    path: `${baseRoutes.userBaseRoutes}booking-past-details`,
  },
  ADDREVIEW: {
    path: `${baseRoutes.userBaseRoutes}add-review`,
  },
  HELPS: {
    path: `${baseRoutes.userBaseRoutes}help-support`,
  },
  USERFAQS: {
    path: `${baseRoutes.userBaseRoutes}faq`,
  },
  USERABOUT: {
    path: `${baseRoutes.userBaseRoutes}about-us`,
  },
};

export default accessRoute;
